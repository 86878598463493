export default function Alert({ data }) {
    const { type, content } = data

    let colour = ''
    switch (type) {
        case 'success':
            colour = 'green'
            break
        case 'warning':
            colour = 'yellow'
            break
        case 'error':
            colour = 'red'
            break
        default:
            colour = 'blue'
    }

    return (
        <div className={`w-full bg-${colour}-200 p-3 text-center`}>
            {content}
        </div>
    )
}
