import { useRecoilState } from 'recoil'
import { userState } from '@atoms/index'

export default function Welcome() {
    const [userData] = useRecoilState(userState)

    return (
        <section className="py-10">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap justify-center text-center">
                    <div className="w-full lg:w-6/12 px-4">
                        <h2 className="text-5xl font-semibold text-black">
                            Hello {userData ? userData.name : ''}
                        </h2>
                    </div>
                </div>
            </div>
        </section>
    )
}
