import Link from 'next/link'

interface Props {
    href?: string
    onClick?: any
    children: any
}

const Button = ({ href, onClick, children, ...props }: Props) => {
    if (href) {
        return (
            <Link
                href={href}
                className="inline-flex items-center border border-black bg-black text-white rounded-md font-bold py-4 px-6 hover:bg-grey-500 hover:text-white"
            >
                {children}
            </Link>
        )
    }

    return (
        <button
            className="border border-black bg-black text-white rounded-sm font-bold py-4 px-6 mr-2 flex items-center hover:bg-grey-500 hover:text-white"
            onClick={() => onClick()}
            {...props}
        >
            {children}
        </button>
    )
}

export default Button
