export const affiliateClick = (
    userId: number,
    siteId: number,
    campaignId: number,
    currentDateTime: string) => {
    const affiliateCookie = getAffiliateCookie()

    if (affiliateCookie) {
        const cookieExpired = isCookieExpired(affiliateCookie)
        if (cookieExpired) {
            deleteCookie('affiliate')
        }
    }

    fetch('/api/affiliates/visit', {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
            userId: userId,
            siteId: siteId,
            campaignId: campaignId,
            currentDateTime: currentDateTime
        }),
    })
        .then((response) => response.json())
        .then(() => {
            setAffiliateCookie(userId, siteId)
        })
        .catch((error) => {
            console.error(error)
        })
}

export const setAffiliateCookie = (userId: number, siteId: number) => {
    // Set the affiliate cookie
    const affiliateCode = `${userId}-${siteId}` // Replace with the actual affiliate code
    const expirationDate = new Date()
    expirationDate.setDate(expirationDate.getDate() + 30) // Expires in 30 days

    document.cookie = `affiliate=${affiliateCode}; expires=${expirationDate.toUTCString()}; path=/`
}

export const getAffiliateCookie = () => {
    const cookies = document.cookie.split(';')
    for (const cookie of cookies) {
        const [name, value] = cookie.trim().split('=')
        if (name === 'affiliate') {
            return value // This is the affiliate code
        }
    }
    return null // Cookie not found
}

export const isCookieExpired = (cookie: any) => {
    // Parse the cookie's expiration date
    const expirationDate = new Date(cookie)

    // Compare the expiration date with the current date
    return expirationDate <= new Date()
}

function deleteCookie(cookie: any) {
    document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
}

export const affiliateSale = (userId: number, siteId: number) => {
    const affiliateCookie = getAffiliateCookie()

    if (affiliateCookie) {
        const cookieExpired = isCookieExpired(affiliateCookie)
        if (cookieExpired) {
            deleteCookie('affiliate')
        }
    }

    fetch('/api/affiliates/sale', {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ userId: userId, siteId: siteId }),
    })
        .then((response) => response.json())
        .then(() => {
            setAffiliateCookie(userId, siteId)
        })
        .catch((error) => {
            console.error(error)
        })
}


export const expireReferrals = () => {
    const itemStr = localStorage.getItem('referral')
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    if (now.getTime() > item.expiry) {
        localStorage.removeItem('referral')
        return null
    }
}
