'use client'

import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { userState, siteState } from '@atoms/index'
import { useRouter, useSearchParams } from 'next/navigation'
import { DateTime } from 'luxon'

import Dashboard from '@components/dashboard'
import Alert from '@components/alert'
import { affiliateClick } from '@lib/services/affiliatesService'
import RegisterLandingTwo from '@components/register-landing-two'
import { API_USER } from '@lib/api-routes'

export default function HomePage() {
    const [userData, setUserData] = useRecoilState<any>(userState)
    const [siteData] = useRecoilState<any>(siteState)
    const [siteAlert, setSiteAlert] = useState<any>(null)

    const router = useRouter()
    const searchParams = useSearchParams()

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        if (searchParams.get('success')) {
            setSiteAlert({
                type: 'success',
                content: 'Order placed! Enjoy your purchase!',
            })
            fetch(`${process.env.API_URL}${API_USER}`, {
                method: 'POST',
                body: JSON.stringify({ username: userData.username }),
            })
                .then((response) => response.json())
                .then((response) => {
                    setUserData(response.data)
                })
                .catch((error) => {
                    console.error(error)
                    if (error.response.status === 403) {
                        localStorage.clear()
                        router.push('/')
                    }
                })
        }

        if (searchParams.get('canceled')) {
            setSiteAlert({
                type: 'warning',
                content:
                    "Order canceled -- continue to look around and try again when you're ready.",
            })
        }
    }, [])

    useEffect(() => {
        const londonTime = DateTime.now().setZone('Europe/London')
        const currentDateTime = londonTime.toFormat('yyyy-MM-dd HH:mm:ss')

        if (searchParams.get('ref') && siteData) {
            affiliateClick(
                Number(searchParams.get('ref')),
                siteData.id,
                Number(searchParams.get('campaignId')),
                currentDateTime
            )
        }
    }, [searchParams])

    return (
        <>
            {!userData && <RegisterLandingTwo />}

            {siteData?.notice && (
                <div
                    id="alert-1"
                    className="flex items-center justify-center p-4 bg-indigo-700 text-white text-center"
                    role="alert"
                >
                    <div className="ml-3 text-sm font-medium flex items-center">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: siteData.notice.text,
                            }}
                        ></span>
                        {siteData.notice && (
                            <a
                                className="ml-4 flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-900 bg-white hover:bg-theme-50"
                                href={siteData.notice.action}
                            >
                                {siteData.notice.actionText}
                            </a>
                        )}
                    </div>
                </div>
            )}

            {!userData && !siteData?.notice && (
                <div
                    id="alert-1"
                    className={`flex items-center justify-center p-4 bg-${siteData?.design?.theme}-700 text-white text-center`}
                    role="alert"
                >
                    <div className="ml-3 text-sm font-medium flex items-center">
                        For a limited time only, get 30 free credits to spend
                        when you sign up.{' '}
                        <a href="/register" className="ml-2">
                            Register now!
                        </a>
                    </div>
                </div>
            )}

            {userData && (
                <>
                    {siteAlert && <Alert data={siteAlert} />}
                    <Dashboard />
                </>
            )}
        </>
    )
}
