import { useRecoilState } from 'recoil'
import { siteState } from '@atoms/index'

import { SiteDataType } from '@lib/types/siteData.types'
import Link from 'next/link'

const RegisterLandingTwo = () => {
    const [siteData] = useRecoilState<SiteDataType | null>(siteState)

    if (!siteData) {
        return <></>
    }
    const { design, meta, seo } = siteData

    return (
        <div>
            {meta?.bonus && (
                <div
                    id="alert-1"
                    className={`flex items-center justify-center p-4 bg-${design.theme}-700 text-white text-center absolute z-50 w-full`}
                    role="alert"
                >
                    <div className="ml-3 text-sm font-medium flex items-center">
                        For a limited time only, get {meta?.bonus} free
                        bonus credits to spend when you register
                    </div>
                </div>
            )}
            <div className="bg-hero w-full bg-cover bg-center bg-no-repeat h-screen fixed z-0"></div>
            <div className="bg-overlay fixed top-0 right-0 bottom-0 left-0 w-screen h-screen z-10"></div>

            <div className="flex flex-col items-center justify-center h-screen relative z-20 p-8">
                <h1 className="text-5xl text-white mb-8">
                    {seo.title}
                </h1>
                <div className=" text-white text-2xl text-center mb-8">
                    {seo.description}
                </div>

                <div className="flex flex-col md:flex-row gap-4 max-w-2xl w-full">
                    <Link
                        href="/register"
                        className={`bg-${design.theme}-600 text-white p-4 w-full rounded-full tracking-wide font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-${design.theme}-700 shadow-lg text-center`}
                    >
                        Register
                    </Link>
                    <Link
                        href="/login"
                        className={`bg-white text-${design.theme}-600 p-4 w-full rounded-full tracking-wide font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-gray-200 shadow-lg text-center`}
                    >
                        Login
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default RegisterLandingTwo
